import styled, { css } from 'styled-components/native';

import { navigationLinkStyles } from './constants';

import {
  AnchorProps,
  CheckCircleProps,
  ModalProps,
  AnimatedPanelContainerProps,
  ButtonProps,
  DefaultTheme,
  LayoutProp,
  NavigationHeaderProps,
  NavigationLinkPressableProps,
  NavigationLinksContainerProps,
  ProfileMenuToggleIconProps,
  SidePanelContainerProps,
} from './types';

import { Anchor } from 'app/components/Common/Anchor';
import { Animated } from 'react-native';
import { Badge } from 'react-native-elements';
import { Button } from 'react-native-elements';
import { CheckCircle } from 'app/components/Common/svg-components';
import { Divider } from 'react-native-elements';
import { Icon } from 'react-native-elements';
import { LiveChatSolid } from 'app/components/Common/svg-components';
import { FocusableView } from 'app/components/Common/FocusableView';
import { Modal } from 'app/components/Common/Modal';
import { default as PhoneInput } from 'app/components/Common/PhoneInput';
import { Platform } from 'react-native';
import { ProfileAvatar } from 'app/components/App/Navigation/ProfileAvatar';
import { RedCLogo } from 'app/components/Common/svg-components';
import { RedHorizontalLogo } from 'app/components/Common/svg-components';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { SafeAreaView } from 'react-native';
import SizedImage from 'app/components/Common/SizedImage';
import { Text } from 'app/components/Common/Styled';
import { TextArea } from 'app/components/Common/TextArea';
import theme from 'app/util/theme';
import { View } from 'app/components/Common/Styled';
import { withBadge } from 'react-native-elements';
import { IdleMoon as IdleMoonRaw } from 'app/components/Common/svg-components';

const fixedPosition = Platform.select({ default: 'absolute', web: 'fixed' });

const getNavigationLinkBottomBorderStyle = (
  isActive: boolean,
  layout: LayoutProp,
  theme: DefaultTheme
) => {
  switch (true) {
    case hasTopNav(theme) && isActive && layout === 'row':
      return css`
        border-bottom-color: ${theme.colors.black};
        border-bottom-width: ${navigationLinkStyles.underlineHeight}px;
      `;
    case !hasTopNav(theme):
      return css`
        border-bottom-color: ${theme.colors.defaultDark};
        border-bottom-width: 1px;
      `;
  }
};

const getNavigationLinkTextStyle = (
  isActive: boolean,
  layout: LayoutProp,
  theme: DefaultTheme
) => {
  const topPaddingAdjustment =
    layout === 'row' ? navigationLinkStyles.underlineHeight / 2 : 0;

  switch (true) {
    case hasTopNav(theme) && isActive:
      return css`
        color: ${theme.colors.darkBlue};
        font-family: ${theme.fontFamilyMedium};
        font-size: ${theme.fontSizes.body2}px;
        padding: ${theme.spacing * 0.75}px;
        padding-top: ${theme.spacing * 0.75 + topPaddingAdjustment}px;
      `;
    case hasTopNav(theme):
      return css`
        color: ${theme.colors.mediumBlue};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizes.body2}px;
        padding: ${theme.spacing * 0.75}px;
      `;
    case !hasTopNav(theme):
      return css`
        color: ${theme.colors.primary};
        font-family: ${theme.fontFamilyBold};
        font-size: ${theme.fontSizes.body3}px;
        margin-bottom: ${theme.spacing / 8}px;
        padding: ${theme.spacing / 2}px;
      `;
  }
};

const hasTopNav = (theme: DefaultTheme) => {
  return theme.windowDimensions.width > theme.breakpoints.small;
};

const CurrentEnvironmentText = styled(Text)`
  ${({ theme }) => css`
    background-color: #ccc;
    font-size: ${theme.fontSizes.small}px;
    padding: ${theme.spacing / 2}px;
    width: 100%;
  `}
`;

const DetailsText = styled(Text)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xsmall}px;
    margin: ${theme.spacing / 4}px auto;
  `}
`;

const HamburgerIcon = styled(Icon).attrs(({ theme }) => {
  return {
    size: 36,
    name: 'menu',
    color: theme.colors.primary,
  };
})``;

const HamburgerIconWithBadge = withBadge(' ', {
  badgeStyle: {
    backgroundColor: theme.colors.badge,
  },
  status: 'primary',
  right: -2,
})(HamburgerIcon);

export const LiveChatIcon = styled(LiveChatSolid).attrs({
  iconStyle: {
    marginRight: 10,
  },
})``;

export const IdleMoon = styled(IdleMoonRaw).attrs(({ theme }) => ({
  height: theme.spacing * 0.75,
  width: theme.spacing * 0.75,
}))``;

export const NavBadge = styled(Badge).attrs(({ theme }) => {
  return {
    badgeStyle: {
      backgroundColor: theme.colors.badge,
      borderRadius: navigationLinkStyles.badgeHeight / 2,
      height: navigationLinkStyles.badgeHeight,
      minWidth: navigationLinkStyles.badgeHeight,
    },
    containerStyle: {
      paddingBottom: theme.spacing / 2,
      marginLeft: hasTopNav(theme) ? theme.spacing / -2 : -4,
      position: 'absolute',
      right: 0,
    },
    status: 'primary',
    textStyle: {
      fontFamily: theme.fontFamilyBold,
      fontSize: theme.fontSizes.xsmall,
    },
  };
})``;

const NavigationLinkPressable = styled(
  ResponsivePressable
)<NavigationLinkPressableProps>`
  ${({ isActive, layout, theme }) => css`
    align-items: center;
    flex-direction: row;
    ${getNavigationLinkBottomBorderStyle(isActive, layout, theme)}
  `}
`;

const NavigationLinksSectionContainer = styled(
  View
)<NavigationLinksContainerProps>`
  ${({ layout: specifiedLayout, theme }) => {
    const isHorizontal = (theme: DefaultTheme) =>
      theme.windowDimensions.width > theme.breakpoints.small;
    const defaultLayout = isHorizontal(theme) ? 'row' : 'column';

    return css`
      flex-direction: ${specifiedLayout || defaultLayout};
    `;
  }}
`;

const NavigationLinkText = styled(Text)<NavigationLinkPressableProps>`
  ${({ isActive, layout, theme }) => css`
    ${getNavigationLinkTextStyle(isActive, layout, theme)}
  `}
`;

const OneLineOfText = styled(Text).attrs({
  numberOfLines: 1,
})`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

const RowContainer = styled(View)`
  ${({ theme }) => css`
    background-color: white;
    height: 100%;
    min-height: ${theme.navigationHeight - 2}px;
    justify-content: center;
  `}
`;

export const CarrumLogoStyles = {
  Wrapper: styled(ResponsivePressable)`
    ${({ theme }) => css`
      justify-content: center;
      padding-left: ${theme.spacing * 0.5}px;
      padding-right: ${theme.spacing * 1.5}px;
    `}
  `,

  Logo: {
    Narrow: RedCLogo,
    Wide: RedHorizontalLogo,
  },
};

export const ChatButtonStyles = {
  Badge: styled(NavBadge).attrs(({ theme }) => {
    return {
      containerStyle: {
        paddingBottom: theme.spacing / 2,
        paddingLeft: theme.spacing,
        marginLeft: hasTopNav(theme) ? theme.spacing / -2 : -4,
      },
    };
  })``,

  Button: styled(Button).attrs<ButtonProps>(({ theme, title }) => {
    const styleObject = {
      buttonStyle: {
        marginHorizontal: theme.spacing / 4,
        paddingHorizontal: theme.spacing,
        paddingVertical: theme.spacing * 0.25,
      },
      title,
    };

    return { ...styleObject, iconContainerStyle: { margin: 10 } };
  })<ButtonProps>`
    ${({ theme }) => css`
      margin-right: ${theme.spacing / 2}px;
    `}
  `,

  ButtonText: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.primary};
      font-size: ${theme.fontSizes.body2}px;
      /* padding-right: ${theme.spacing}px; */
    `}
  `,

  ButtonSubheaderText: styled(Text)`
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.xsmall}px;
      margin-left: ${theme.spacing / 4}px;
    `}
  `,

  ButtonSubheaderContainer: styled(View)`
    flex-direction: row;
    align-items: center;
  `,

  ButtonTextContainer: styled(View)`
    flex-direction: column;
  `,

  GreenDot: styled(View)`
    ${({ theme }) => css`
      background-color: ${theme.colors.brightGreen};
      height: ${theme.spacing / 2}px;
      width: ${theme.spacing / 2}px;
      border-radius: 50%;
    `}
  `,
};

export const ClientLogoStyles = {
  Wrapper: View,
  Logo: SizedImage,
};

export const ContentLinksStyles = {
  Wrapper: NavigationLinksSectionContainer,
};

export const CurrentEnvironmentStyles = {
  BoldText: styled(CurrentEnvironmentText)`
    ${({ theme }) => css`
      font-family: ${theme.fontFamilyBold};
    `}
  `,

  Text: CurrentEnvironmentText,

  Wrapper: styled(ResponsivePressable).attrs({
    activeOpacity: 1,
  })`
    flex: 1;
    justify-content: flex-end;
    opacity: 0;
  `,
};

export const DevToolsLinkStyles = {
  Pressable: NavigationLinkPressable,
  Text: NavigationLinkText,
};

export const GetHelpModalStyles = {
  Anchor: styled(Anchor).attrs<AnchorProps>(({ theme }) => ({
    titleStyle: {
      textAlign: 'center',
      marginBottom: theme.spacing / 2,
      marginTop: theme.spacing / 2,
      fontSize: theme.fontSizes.h2,
      fontFamily: theme.fontFamilyMedium,
    },
  }))<AnchorProps>``,

  CallYouText: styled(Text)`
    ${({ theme }) => css`
      margin: 0 auto;
      color: ${theme.colors.bodyText};
      font-family: ${theme.fontFamilyMedium};
      font-size: ${theme.fontSizes.h2}px;
      padding-bottom: ${theme.spacing / 2}px;
      padding-top: ${theme.spacing / 2}px;
    `}
  `,

  Confirmation: {
    Body: styled(Text)`
      text-align: center;
    `,

    Header: styled(Text)`
      ${({ theme }) => css`
        color: ${theme.colors.success};
        font-family: ${theme.fontFamilyBold};
        margin: ${theme.spacing / 2}px auto;
      `}
    `,
  },

  DetailsText,

  DetailsTextBold: styled(DetailsText)`
    ${({ theme }) => css`
      font-family: ${theme.fontFamilyBold};
    `}
  `,

  Divider: styled(Divider)`
    margin-top: ${({ theme }) =>
      theme.isNarrow ? `${theme.spacing / 2}px` : `${theme.spacing}px`};
  `,

  GreenCheckMark: styled(CheckCircle).attrs<CheckCircleProps>(({ theme }) => ({
    color: theme.colors.success,
    size: 67,
  }))<CheckCircleProps>`
    margin: 0 auto;
  `,

  Header: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.bodyText};
      font-family: ${theme.fontFamilyMedium};
      font-size: ${theme.fontSizes.h2}px;
      margin: ${theme.spacing / 2}px auto;
      padding-top: ${theme.spacing}px;
    `}
  `,

  Modal: styled(Modal).attrs(({ theme }) => ({
    overlayStyleOverrides: {
      backgroundColor: theme.colors.sand,
    },
  }))<ModalProps>``,

  PhoneInput,

  Subtext: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.bodyText};
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizes.body2}px;
      font-style: italic;
      padding-bottom: ${theme.spacing / 2}px;
    `}
  `,

  Text: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.bodyText};
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizes.body2}px;
      padding: ${theme.spacing / 4}px;
    `}
  `,

  TextArea,
};

export const GuestLinksStyles = {
  Wrapper: NavigationLinksSectionContainer,
};

export const MenuIconStyles = {
  HamburgerIcon,
  HamburgerIconWithBadge,
};

export const NavigationLinksStyles = {
  Wrapper: View,
};

export const NavigationLinkStyles = {
  Badge: styled(Badge).attrs(({ theme }) => {
    return {
      badgeStyle: {
        backgroundColor: theme.colors.badge,
        borderRadius: navigationLinkStyles.badgeHeight / 2,
        height: navigationLinkStyles.badgeHeight,
        minWidth: navigationLinkStyles.badgeHeight,
      },
      containerStyle: {
        paddingBottom: theme.spacing / 2,
        marginLeft: hasTopNav(theme) ? theme.spacing / -2 : -4,
      },
      status: 'primary',
      textStyle: {
        fontFamily: theme.fontFamilyBold,
        fontSize: theme.fontSizes.xsmall,
      },
    };
  })``,

  Pressable: NavigationLinkPressable,

  Text: NavigationLinkText,
};

export const NavigationStyles = {
  Containers: {
    Center: styled(RowContainer)`
      align-items: center;
      flex-direction: row;
    `,

    Left: styled(RowContainer)`
      align-items: center;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-start;
    `,

    Right: styled(RowContainer)`
      ${({ theme }) => css`
        align-items: center;
        flex-direction: row;
        padding-right: ${Platform.select({
          default: 0,
          ios: theme.spacing * 0.5,
        })}px;
      `}
    `,
  },

  Header: styled(SafeAreaView)<NavigationHeaderProps>`
    ${({ theme, topInset }) => {
      return css`
        align-items: center;
        background-color: ${theme.colors.white};
        border-bottom-color: #eeeeee;
        border-bottom-width: 1px;
        box-shadow: 0px 2px 2px ${theme.colors.black}33;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: ${theme.spacing}px;
        padding-top: ${Platform.select({
          default: topInset,
          web: 0,
        })}px;
      `;
    }}
  `,

  HeaderAnimation: styled(Animated.View)`
    ${({ theme }) => css`
      left: 0;
      position: ${Platform.select({
        default: 'absolute',
        web: 'fixed',
      })};
      right: 0;
      z-index: ${theme.zIndexes.appNavigation};
      height: ${theme.navigationHeight}px;
    `}
  `,
};

export const NeedHelpCtaStyles = {
  Button: styled(Button).attrs<ButtonProps>(({ theme }) => {
    const isSmallScreen =
      theme.windowDimensions.width < theme.breakpoints.large;

    return {
      buttonStyle: { padding: isSmallScreen ? 0 : theme.spacing / 2 },
      icon: {
        color: theme.colors.primary,
        name: 'call',
      },
      title: isSmallScreen ? '' : 'Need help? Talk to us',
      titleStyle: {
        marginLeft: isSmallScreen ? 0 : theme.spacing / 2,
      },
      type: isSmallScreen ? 'clear' : 'outline',
    };
  })<ButtonProps>`
    ${({ theme }) => css`
      margin-right: ${theme.spacing / 2}px;
    `}
  `,
};

export const ProfileInfoStyles = {
  Avatar: ProfileAvatar,

  Email: styled(OneLineOfText)`
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.small}px;
    `}
  `,

  Identity: styled(View)`
    ${({ theme }) => css`
      flex: 1 0 auto;
      padding-left: ${theme.spacing * 0.75}px;
    `}
  `,

  Name: styled(OneLineOfText)`
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.body2}px;
    `}
  `,

  Wrapper: styled(ResponsivePressable).attrs({
    flexDirection: 'row',
  })`
    ${({ theme }) => css`
      align-items: center;
      border-bottom-color: ${theme.colors.lightBlue};
      border-bottom-width: 1px;
      justify-content: flex-start;
      margin-bottom: ${theme.spacing * 0.5}px;
      padding-bottom: ${theme.spacing * 1.25}px;
      padding-left: ${theme.spacing * 0.5}px;
      padding-right: ${theme.spacing * 0.5}px;
      padding-top: ${theme.spacing * 0.5}px;
    `}
  `,
};

export const ProfileLinksStyles = {
  Wrapper: NavigationLinksSectionContainer,
};

export const ProfileMenuStyles = {
  Panel: styled(View)`
    ${({ theme }) => css`
      background-color: ${theme.colors.white};
      border-radius: ${theme.spacing * 0.5}px;
      box-shadow: 0px 0px ${theme.spacing * 0.5}px ${theme.colors.black}40;
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing * 0.75}px;
      position: ${fixedPosition};
      right: ${theme.spacing * 1.25}px;
      top: ${theme.spacing * 4.25}px;
      width: ${theme.spacing * 17.5}px;
    `}
  `,

  Wrapper: View,
};

export const ProfileMenuToggleStyles = {
  Avatar: ProfileAvatar,

  Icon: styled(Icon).attrs<ProfileMenuToggleIconProps>(({ isOpen, theme }) => ({
    color: theme.colors.darkBlue,
    containerStyle: { marginLeft: theme.spacing * -0.25 },
    name: isOpen ? 'arrow-drop-down' : 'arrow-right',
    size: theme.spacing * 1.75,
  }))<ProfileMenuToggleIconProps>``,

  Wrapper: styled(FocusableView)`
    ${({ theme }) => css`
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-left: ${theme.spacing * 0.5}px;
    `}
  `,
};

export const SidePanelAnimatedOverlayStyles = {
  Switch: styled(ResponsivePressable)`
    flex: 1;
  `,

  Wrapper: styled(Animated.View)`
    background-color: #00000080;
    flex: 1;
  `,
};

export const SidePanelAnimatedPanelStyles = {
  Panel: styled(View)`
    ${({ theme }) => css`
      flex: 1;
      padding: ${theme.spacing * 1.25}px;
      padding-top: ${Platform.select({
        default: theme.spacing * 2.5,
        web: theme.spacing * 1.25,
      })}px;
      width: ${theme.spacing * 17.5}px;
    `}
  `,
  Wrapper: styled(Animated.View)<AnimatedPanelContainerProps>`
    ${({ isOpen = false, theme }) => css`
      background-color: white;
      bottom: 0;
      ${isOpen &&
      css`
        box-shadow: 2px 0px 8px ${theme.colors.black}80;
      `}
      position: ${Platform.select({
        default: 'absolute',
        web: 'fixed',
      })};
      right: 0;
      top: 0;
    `}
  `,
};

export const SidePanelStyles = {
  Wrapper: styled(View).attrs<SidePanelContainerProps>(
    ({ isOpen }) => {}
  )<SidePanelContainerProps>`
    ${({ isOpen, theme }) => css`
      height: 100%;
      pointer-events: ${isOpen ? 'auto' : 'none'};
      position: ${fixedPosition};
      width: 100%;
      z-index: ${theme.zIndexes.overlay};
    `}
  `,
};
